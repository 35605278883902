import ApplicationController from "../../../plus/src/controllers/application_controller";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import Dashboard from "@uppy/dashboard";
import DragDrop from "@uppy/drag-drop";
import "@uppy/dashboard/dist/style.min.css";

export class UppyController extends ApplicationController {
  static values = {
    mode: { type: String, default: "dashboard" },
    directory: { type: String, default: "user_uploads" },
    directUpload: { type: Boolean, default: true },
    multiFile: { type: Boolean, default: false },
    fileTypes: { type: Array, default: ["image/*", "video/*", "application/pdf", "application/zip"] },
    note: { type: String, default: "Supported file type: PDF, JPG, PNG, TIFF, ZIP, MP4, MOV, MPEG." },
    height: { type: String, default: null },
    width: { type: String, default: null },
    successReflex: { type: String, default: null },
    uploadStartReflex: { type: String, default: null },
    uploadProgressReflex: { type: String, default: null },
    autoSubmit: { type: Boolean, default: false },
    enableButton: { type: String }, // ID of the button to enable after upload completed
  };

  static targets = ["fileInput", "container", "done", "deleteOnDone"];

  connected() {
    let maxFiles;
    let uppyTarget;
    if (this.hasContainerTarget) {
      uppyTarget = this.containerTarget;
    } else {
      uppyTarget = this.element;
    }
    if (this.multiFileValue) {
      // TODO: make this configurable
      // realisticaly I dont think anyone would want to upload more than 50 files at once
      // if really needed extract the restriction config to an object without maxNumberOfFiles
      maxFiles = 50;
    } else {
      maxFiles = 1;
    }
    const thisController = this;
    const uppy = new Uppy({
      autoProceed: true,
      allowMultipleUploadBatches: this.multiFileValue,
      restrictions: {
        maxNumberOfFiles: maxFiles,
        allowedFileTypes: this.fileTypesValue,
      },
    });
    this.setUppyOptions(uppy, uppyTarget, {
      inline: true,
      width: 550,
      height: 403,
      proudlyDisplayPoweredByUppy: false,
      note: this.noteValue,
      ...(this.modeValue === "dashboard" && this.hasDoneTarget
        ? {
            // NOTE: the below has some outdated documentation in https://uppy.io/docs/dashboard/#donebuttonhandler, but see implementation details' notes.
            doneButtonHandler: () => {
              uppy.cancelAll(); // from https://community.transloadit.com/t/uppy-vue-how-can-i-manually-reset-the-dashboard-from-the-donebuttonhandler-method/16695
              uppy.getPlugin("Dashboard").closeModal(); // from https://uppy.io/docs/dashboard/#closemodal
              thisController.closeDone();
            },
          }
        : {}),
    });
    if (this.directUploadValue) {
      uppy
        .use(AwsS3, {
          shouldUseMultipart: file => true,
          endpoint: "/",
          headers: {
            directory: this.directoryValue,
          },
        })
        .on("complete", result => {
          this.callSuccessReflex(result);
          this.enableTargetButton();
          const uploadedFiles = result.successful.map(file => {
            const parsed = new URL(file.response.body.location);
            return {
              path: parsed.pathname.substring(1), // remove leading /
              file_type: file.type,
              file_name: file.name,
              file_size: file.size,
              file_full_path: parsed.pathname,
            };
          });

          // TODO maybe allow for custom field names
          uploadedFiles.map(f => {
            this.element.appendChild(this.createInput("uploads", "file_path", f.path));
            this.element.appendChild(this.createInput("uploads", "file_type", f.file_type));
            this.element.appendChild(this.createInput("uploads", "file_name", f.file_name));
            this.element.appendChild(this.createInput("uploads", "file_size", f.file_size));
          });

          this.autoSubmit();
        });
    } else {
      // If not direct upload, we need to add the file input to the form
      // TODO: handle multiple files by creating multiple inputs
      uppy.on("complete", result => {
        this.callSuccessReflex(result);
        this.enableTargetButton();
        let file = result.successful[0].data;
        let container = new DataTransfer();
        container.items.add(file);
        this.fileInputTarget.files = container.files;
        // Dispatch change on the form so this controller plays nice with other controllers that listen to change events
        this.element.dispatchEvent(new Event("change", { bubbles: true }));
      });
      uppy.on("file-removed", _file => {
        this.fileInputTarget.value = null;
        // Dispatch change on the form so this controller plays nice with other controllers that listen to change events
        this.element.dispatchEvent(new Event("change", { bubbles: true }));
      });
    }
    if (this.hasUploadStartReflexValue && this.uploadStartReflexValue) {
      uppy.on("upload", (uploadId, files) => {
        this.stimulate(this.uploadStartReflexValue, { upload_id: uploadId, files: files });
      });
    }
    if (this.hasUploadProgressReflexValue && this.uploadProgressReflexValue) {
      uppy.on("upload-progress", (file, progress) => {
        this.stimulate(this.uploadProgressReflexValue, { file: file, progress: progress });
      });
    }
    document.addEventListener("uppy-disable-upload-button", this.disableDoneButton.bind(this));
    document.addEventListener("trigger_file_failed_state", this.enableDoneButtonWhenComplete.bind(this));
    document.addEventListener("trigger_file_succeeded_state", this.enableDoneButtonWhenComplete.bind(this));
  }

  createInput(inputName, paramName, value) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = `${inputName}[]${paramName}`;

    input.value = value;
    return input;
  }

  setUppyOptions(uppy, target, options) {
    switch (this.modeValue) {
      case "dashboard":
        uppy.use(Dashboard, {
          target: target,
          ...options,
          ...(this.hasHeightValue ? { height: this.heightValue } : {}),
          ...(this.hasWidthValue ? { width: this.widthValue } : {}),
        });
        break;
      case "drag-drop":
        const dragdropprops = {
          target: target,
          ...options,
          ...(this.hasHeightValue ? { height: this.heightValue } : {}),
          ...(this.hasWidthValue ? { width: this.widthValue } : {}),
        };
        uppy.use(DragDrop, dragdropprops);
        break;
    }
  }

  enableTargetButton() {
    if (this.hasEnableButtonValue && this.enableButtonValue) {
      document.getElementById(`${this.enableButtonValue}`).removeAttribute("disabled");
    }
  }

  callSuccessReflex(result) {
    if (this.hasSuccessReflexValue && this.successReflexValue) {
      this.stimulate(this.successReflexValue, { upload_result: result });
    }
  }

  autoSubmit() {
    if (this.hasAutoSubmitValue && this.autoSubmitValue) {
      this.element.requestSubmit();
    }
  }

  closeDone() {
    this.doneTargets.forEach(doneTarget => {
      doneTarget.classList.remove("show");
    });
    this.deleteOnDoneTargets.forEach(deleteOnDoneTarget => {
      deleteOnDoneTarget.remove();
    });
  }

  disableDoneButton(event) {
    this.uppyDoneButton().setAttribute("disabled", true);
  }

  enableDoneButtonWhenComplete(event) {
    if (!this.hasDoneTarget) return;
    const allCompleted = this.doneTargets.every(target =>
      [...target.querySelectorAll(".status-file")].every(el => el.dataset.completed === "true"),
    );
    const doneButton = this.uppyDoneButton();
    if (allCompleted) {
      doneButton?.removeAttribute("disabled");
    } else {
      doneButton?.setAttribute("disabled", true);
    }
  }

  uppyDoneButton() {
    return this.containerTarget.querySelector(".uppy-StatusBar-actionBtn--done");
  }
}
