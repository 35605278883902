import ApplicationController from "../../../../plus/src/controllers/application_controller";


export class PrelineTagInputController extends ApplicationController {
  static targets = ["input"]

  handleChange(e) {
    const payload = e.detail.payload || [];

    const currentOptions = new Map(
        [...e.target.options].map(opt => [opt.value, opt])
      );

      payload.forEach(value => {
        if (!currentOptions.has(value)) {
          const option = document.createElement('option');
          option.value = value;
          option.text = value;
          option.selected = true;
        e.target.appendChild(option);
      }
    });
  }
}
