import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);

    this.sortable = Sortable.create(this.element, {
      onEnd: this.#onDrop.bind(this),
    });
  }

  #onDrop(event) {
    const { newIndex } = event;
    const element = this.#findElementByIdx(newIndex);

    const payload = { ...element.dataset, ...{ newIndex: newIndex + 1 } };
    this.stimulate("UnitImageSortReflex#sort", payload);
  }

  #findElementByIdx(elementIdx) {
    return this.element.children[elementIdx];
  }
}
